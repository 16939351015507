<template>
  <v-container fluid>
    <v-form
      class="custom-form"
      ref="purchaseForm"
      @submit.prevent="savePurchase"
    >
      <v-row dense>
        <v-col cols="9">
          <v-card :elevation="1" color="grey lighten-3">
            <v-row no-gutters>
              <v-col cols="12">
                <v-toolbar color="#546E7A" :elevation="1" height="30px" dark>
                  <v-toolbar-title class="subtitle-2"
                    >Purchase Entry</v-toolbar-title
                  >
                </v-toolbar>
                <v-card-text class="py-2">
                  <v-row no-gutters class="mb-1">
                    <v-col cols="4">
                      <v-row no-gutters>
                        <v-col cols="3">Supplier</v-col>
                        <v-col cols="8">
                          <v-combobox
                            dense
                            outlined
                            hide-details
                            v-model="supplier"
                            @focus="$store.dispatch('supplier/getSuppliers')"
                            :items="$store.getters['supplier/suppliers']"
                            :loading="$store.getters['supplier/loading']"
                            item-text="display_text"
                            item-value="id"
                          >
                          </v-combobox>
                        </v-col>
                        <v-col cols="1">
                          <v-btn
                            to="/supplier"
                            target="_blank"
                            class="ml-1"
                            height="25px"
                            dark
                            small
                            block
                            color="primary"
                          >
                            <v-icon class="pt-1">mdi-plus</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="4">
                      <v-row no-gutters>
                        <v-col cols="3" class="text-right pr-2">Mobile</v-col>
                        <v-col cols="9">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            v-model="mobile"
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="4">
                      <v-row no-gutters>
                        <v-col cols="3" class="text-right pr-2">Address</v-col>
                        <v-col cols="9">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            v-model="address"
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
          <v-row dense>
            <v-col cols="12">
              <v-card :elevation="1" color="blue-grey lighten-3">
                <v-card-text class="py-2">
                  <v-row no-gutters>
                    <v-col cols="4">
                      <v-row no-gutters>
                        <v-col cols="3">Instr.</v-col>
                        <v-col cols="8">
                          <v-combobox
                            dense
                            outlined
                            hide-details
                            v-model="instrument"
                            @focus="
                              $store.dispatch('instrument/getInstruments')
                            "
                            :items="$store.getters['instrument/instruments']"
                            :loading="$store.getters['instrument/loading']"
                            item-text="display_text"
                            item-value="id"
                          >
                          </v-combobox>
                        </v-col>
                        <v-col cols="1">
                          <v-btn
                            to="/store/instrument"
                            target="_blank"
                            class="ml-1"
                            height="25px"
                            dark
                            small
                            block
                            color="primary"
                          >
                            <v-icon class="pt-1">mdi-plus</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="3">
                      <v-row no-gutters>
                        <v-col cols="6" class="text-right pr-2"
                          >Pur. Rate</v-col
                        >
                        <v-col cols="6">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            v-model.number="purchase.purchase_rate"
                            @input="calculateTotalAmount"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="2">
                      <v-row no-gutters>
                        <v-col cols="6" class="text-right pr-2">Quantity</v-col>
                        <v-col cols="6">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            v-model.number="purchase.quantity"
                            @input="calculateTotalAmount"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="2">
                      <v-row no-gutters>
                        <v-col cols="4" class="text-right pr-2">Total </v-col>
                        <v-col cols="8">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            readonly
                            v-model.number="purchase.total_amount"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="1">
                      <v-row no-gutters>
                        <v-col cols="2"></v-col>
                        <v-col cols="10">
                          <v-btn
                            height="28px"
                            dark
                            block
                            color="light-blue darken-2"
                            @click="addToCart"
                            >Add</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-card :elevation="1" color="grey lighten-3">
            <v-toolbar color="#546E7A" :elevation="1" height="30px" dark>
              <v-toolbar-title class="subtitle-2"
                >Invoice Information</v-toolbar-title
              >
            </v-toolbar>
            <v-row no-gutters>
              <v-col cols="12">
                <v-card-text class="py-0 mt-1">
                  <v-row no-gutters>
                    <v-col md="12" cols="12">
                      <v-row no-gutters class="mb-1">
                        <v-col cols="4">Invoice</v-col>
                        <v-col cols="8">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            :value="$store.getters['purchase/invoice']"
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-1">
                        <v-col cols="4">Date</v-col>
                        <v-col cols="8">
                          <v-menu>
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                dense
                                outlined
                                hide-details
                                v-model="purchase.purchase_date"
                                v-bind="attrs"
                                v-on="on"
                                autocomplete="off"
                              >
                                <v-icon slot="prepend-inner"
                                  >mdi-calendar-month</v-icon
                                >
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-model="purchase.purchase_date"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-1">
                        <v-col cols="4">Employee</v-col>
                        <v-col cols="8">
                          <v-combobox
                            dense
                            outlined
                            hide-details
                            v-model="employee"
                            @focus="$store.dispatch('employee/getEmployees')"
                            :items="$store.getters['employee/employees']"
                            item-text="display_text"
                            item-value="id"
                          >
                          </v-combobox>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      
      <v-row dense>
        <v-col cols="9">
          <v-simple-table class="custom-data-table">
            <template>
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Int. Name</th>
                  <th>Int. Category</th>
                  <th>Rate</th>
                  <th>Quantity</th>
                  <th>Total Amount</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(cart, ind) in addtocart" :key="ind">
                  <td>{{ ind + 1 }}</td>
                  <td>{{ cart.name }}</td>
                  <td>{{ cart.category }}</td>
                  <td class="text-right">{{ cart.purchase_rate }}</td>
                  <td class="text-right">{{ cart.quantity }}</td>
                  <td class="text-right">{{ cart.total }}</td>
                  <td class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          small
                          @click="editCartItem(ind)"
                          color="primary"
                          v-on="on"
                          >mdi-circle-edit-outline</v-icon
                        >
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          small
                          @click="removeCart(ind)"
                          color="error"
                          v-on="on"
                          :disabled="editCartItemIndex != null"
                          >mdi-delete-circle-outline</v-icon
                        >
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </td>
                </tr>
                <tr
                  style="display:none"
                  v-bind:style="{ display: addtocart.length > 0 ? '' : 'none' }"
                >
                  <td colspan="4" class="text-center"><strong>Note</strong></td>
                  <td colspan="3" class="text-center">
                    <strong>Sub Total</strong>
                  </td>
                </tr>
                <tr
                  style="display:none"
                  v-bind:style="{ display: addtocart.length > 0 ? '' : 'none' }"
                >
                  <td colspan="4" style="padding:5px 0px">
                    <v-textarea
                      dense
                      outlined
                      hide-details
                      height="7vh"
                      v-model="purchase.note"
                    ></v-textarea>
                  </td>
                  <td colspan="3" class="text-center">
                    <strong> {{ purchase.sub_total }}</strong>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="3" class="pb-0">
          <v-card :elevation="1" color="grey lighten-3">
            <v-row no-gutters>
              <v-col cols="12">
                <v-card-text class="py-0 mt-1">
                  <v-row dense>
                    <v-col md="12" cols="12">
                      <v-row no-gutters class="mb-1">
                        <v-col cols="4">Sub Total</v-col>
                        <v-col cols="8">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            v-model.number="purchase.sub_total"
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-1">
                        <v-col cols="4">VAT</v-col>
                        <v-col cols="3">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            autocomplete="off"
                            @input="calculateTotal"
                            v-model="vatPercent"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="1" class="pl-1">%</v-col>
                        <v-col cols="4">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            autocomplete="off"
                            readonly
                            v-model="purchase.vat"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-1">
                        <v-col cols="4">Discount</v-col>
                        <v-col cols="3">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            v-model.number="disPercent"
                            @input="calculateTotal"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="1" class="pl-1">%</v-col>
                        <v-col cols="4">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            id="discount__amount"
                            v-model.number="disAmount"
                            @input="calculateTotal"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-1">
                        <v-col cols="4">Others</v-col>
                        <v-col cols="8">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            v-model.number="purchase.others"
                            @input="calculateTotal"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-1">
                        <v-col cols="4">Total</v-col>
                        <v-col cols="8">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            readonly
                            v-model.number="purchase.total"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-1">
                        <v-col cols="4">Paid</v-col>
                        <v-col cols="8">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            v-model.number="purchase.paid"
                            @input="calculateDueAmount"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-1">
                        <v-col cols="4">Due</v-col>
                        <v-col cols="4">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            class="pr-1"
                            readonly
                            v-model.number="purchase.due"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            readonly
                            :value="$store.getters['supplier/supplierDue']"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="3"></v-col>
                        <v-col cols="9">
                          <v-row dense>
                            <v-col cols="6">
                              <v-btn
                                height="28px"
                                dark
                                block
                                color="deep-orange"
                                >Clear</v-btn
                              >
                            </v-col>
                            <v-col cols="6">
                              <v-btn
                                height="28px"
                                type="submit"
                                dark
                                block
                                color="light-blue darken-2"
                                :loading="saveLoading"
                                >Save</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import swal from "sweetalert";
export default {
  name: "PurchaseEntry",

  data: () => ({
    purchase: {
      purchase_date: new Date().toISOString().substr(0, 10),
      employee_id: null,
      supplier_id: null,
      instrument_id: null,
      purchase_rate: 0.0,
      quantity: 0,
      total_amount: 0.0,
      sub_total: 0.0,
      vat: 0.0,
      discount: 0.0,
      others: 0.0,
      total: 0.0,
      paid: 0.0,
      due: 0.0,
      previous_due: 0.0,
      note: ""
    },
    vatPercent: 0.0,
    disPercent: 0.0,
    disAmount: 0.0,

    mobile: "",
    address: "",

    supplier: null,
    instrument: null,
    employee: null,
    addtocart: [],

    saveLoading: false,
    editMode: false,
    editCartItemIndex: null,

    deletedCartItemsId: []
  }),

  watch: {
    supplier(supplier) {
      if (supplier == null) return;
      this.purchase.supplier_id = supplier.id;
      this.mobile = supplier.mobile;
      this.address = supplier.address;
      this.$store.dispatch("supplier/getSupplierDue", {
        supplier_id: supplier.id
      });
    },

    instrument(instrument) {
      if (instrument == null) return;
      this.instrument = instrument;
      this.purchase.instrument_id = instrument.id;
      if (this.editCartItemIndex == null)
        this.purchase.purchase_rate = instrument.purchase_rate;
    },

    employee(employee) {
      if (employee == null) return;
      this.purchase.employee_id = employee.id;
    }
  },

  created() {
    this.getInvoice();
    if (this.$route.params.id != undefined)
      this.editPurchase(this.$route.params.id);
  },

  methods: {
    async editPurchase(purchaseId) {
      let purchase = await this.$store.dispatch("purchase/getPurchase", {
        id: purchaseId
      });
      if (purchase == null) this.$router.push(`/purchase-record`);
      this.supplier = purchase.supplier;
      this.employee = purchase.employee;
      this.purchase.purchase_date = purchase.purchase_date;
      this.purchase.sub_total = purchase.sub_total;
      this.vatPercent = ((purchase.vat / purchase.sub_total) * 100).toFixed(2);
      this.purchase.vat = purchase.vat;
      this.disPercent = (
        (purchase.discount / purchase.sub_total) *
        100
      ).toFixed(2);
      this.disAmount = purchase.discount;
      this.purchase.others = purchase.others;
      this.purchase.total = purchase.total;
      this.purchase.paid = purchase.paid;
      this.purchase.due = purchase.due;
      this.purchase.note = purchase.note;
      purchase.purchase_details.forEach(item => {
        this.addtocart.unshift({
          id: item.id,
          instrument_id: item.instrument_id,
          name: item.instrument.name,
          category: item.instrument.instrument_category.name,
          purchase_rate: item.purchase_rate,
          quantity: item.quantity,
          total: item.purchase_total
        });
      });
      this.editMode = true;
      this.$store.dispatch("instrument/getInstruments");
    },

    getInvoice() {
      this.$store.dispatch("purchase/getInvoices");
    },

    addToCart() {
      if (this.instrument == null || this.purchase.instrument_id == null) {
        this.$store.dispatch("snackbar/error", "Select instrument");
        return;
      }

      if (this.purchase.quantity == 0) {
        this.$store.dispatch("snackbar/error", "Choose quantity");
        return;
      }

      let cartInd = this.addtocart.findIndex(
        i => i.instrument_id == this.purchase.instrument_id
      );

      if (this.editCartItemIndex == null) {
        if (cartInd > -1) {
          this.$store.dispatch("snackbar/error", "Instrument existed in cart");
          return;
        }
        this.addtocart.push({
          instrument_id: this.instrument.id,
          name: this.instrument.name,
          category: this.instrument.instrument_category.name,
          purchase_rate: this.purchase.purchase_rate,
          quantity: this.purchase.quantity,
          total: this.purchase.total_amount
        });
      } else {
        if (cartInd > -1 && cartInd != this.editCartItemIndex) {
          let cartItem = this.addtocart[this.editCartItemIndex];
          this.instrument = this.getInstrumentById(cartItem.instrument_id);
          this.$store.dispatch("snackbar/error", "Instrument existed in cart");
          return;
        }
        let currentEditItem = this.addtocart[this.editCartItemIndex];
        currentEditItem.instrument_id = this.instrument.id;
        currentEditItem.name = this.instrument.name;
        currentEditItem.category = this.instrument.instrument_category.name;
        currentEditItem.purchase_rate = this.purchase.purchase_rate;
        currentEditItem.quantity = this.purchase.quantity;
        currentEditItem.total = this.purchase.total_amount;
      }

      this.calculateTotal();
      this.resetCart();
    },

    editCartItem(ind) {
      let item = this.addtocart[ind];
      this.instrument = this.getInstrumentById(item.instrument_id);
      this.purchase.purchase_rate = item.purchase_rate;
      this.purchase.quantity = item.quantity;
      this.purchase.total_amount = item.total;
      this.editCartItemIndex = ind;
    },

    getInstrumentById(instrument_id) {
      let instruments = this.$store.getters["instrument/instruments"];
      let instrument = instruments.filter(ins => ins.id == instrument_id);
      return instrument.length > 0 ? instrument[0] : null;
    },

    resetCart() {
      this.purchase.instrument_id = null;
      this.purchase.purchase_rate = 0;
      this.purchase.quantity = 0;
      this.purchase.total_amount = 0;
      this.instrument = null;
      this.editCartItemIndex = null;
    },

    removeCart(ind) {
      let item = this.addtocart[ind];
      if (item.id != undefined) this.deletedCartItemsId.push(item.id);

      this.addtocart.splice(ind, 1);
      this.calculateTotal();

      if (this.addtocart.length == 0) {
        this.purchase.sub_total = 0.0;
        this.purchase.total = 0.0;
        this.purchase.vat = 0.0;
        this.purchase.discount = 0.0;
        this.purchase.others = 0.0;
        this.vatPercent = 0;
        this.disPercent = 0;
        this.disAmount = 0;
      }
    },

    calculateTotalAmount() {
      this.purchase.total_amount =
        this.purchase.purchase_rate * this.purchase.quantity;
    },

    calculateTotal() {
      this.purchase.sub_total = this.addtocart.reduce((prev, curr) => {
        return prev + parseFloat(curr.total);
      }, 0);
      this.purchase.vat = (
        (this.purchase.sub_total * this.vatPercent) /
        100
      ).toFixed(2);

      if (event.target.id == "discount__amount") {
        this.disPercent = (
          (this.disAmount / this.purchase.sub_total) *
          100
        ).toFixed(2);
        this.purchase.discount = this.disAmount;
      } else {
        this.disAmount = (
          (this.purchase.sub_total * this.disPercent) /
          100
        ).toFixed(2);
        this.purchase.discount = this.disAmount;
      }

      let OthersAmount =
        typeof this.purchase.others == "string" ? 0 : this.purchase.others;

      this.purchase.total = (
        parseFloat(this.purchase.sub_total) +
        parseFloat(this.purchase.vat) +
        parseFloat(OthersAmount) -
        this.disAmount
      ).toFixed(2);

      if (!this.editMode) this.purchase.paid = this.purchase.total;
      this.calculateDueAmount();
    },

    calculateDueAmount() {
      this.purchase.due =
        parseFloat(this.purchase.total) - parseFloat(this.purchase.paid);
    },

    async savePurchase() {
      if (this.purchase.supplier_id == null) {
        this.$store.dispatch("snackbar/error", "Select supplier");
        return;
      }

      if (this.addtocart.length == 0) {
        this.$store.dispatch(
          "snackbar/error",
          "Cart is empty ! Plase add to cart."
        );
        return;
      }

      if (this.purchase.employee_id == null) {
        this.$store.dispatch("snackbar/error", "Select employee");
        return;
      }

      this.saveLoading = true;

      delete this.purchase["quantity"];
      delete this.purchase["total_amount"];
      delete this.purchase["instrument_id"];
      delete this.purchase["purchase_rate"];

      if (this.$route.params.id != undefined && this.editMode == true) {
        let res = await this.$store.dispatch("purchase/savePurchase", {
          ...this.purchase,
          cart: this.addtocart,
          deleteItemsId: this.deletedCartItemsId,
          id: this.$route.params.id
        });
        if (res.isSuccess) this.$router.go(-1);
      } else {
        let res = await this.$store.dispatch("purchase/savePurchase", {
          ...this.purchase,
          cart: this.addtocart
        });
        if (res.isSuccess) {
          this.resetForm();

          swal({
            title: "Do you want to show invoice?",
            icon: "success",
            buttons: true
          }).then(response => {
            if (response) {
              if (response) this.$router.push(`/purchase-invoice/${res.id}`);
            }
          });
        }
      }

      this.saveLoading = false;
    },

    resetForm() {
      this.$refs.purchaseForm.reset();
      this.addtocart = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.v-icon.v-icon {
  font-size: 18px !important;
  top: -3px !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 12px;
  padding: 0px 1px;
  background: #607d8b;
  color: #ffffff;
  border: 1px solid #ccc;
  text-align: center;
  height: 24px !important;
  letter-spacing: 0.7px;
}
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
  height: 20px !important;
  padding: 0px 5px !important;
  font-size: 12px !important;
  border: 1px solid #ccc !important;
  text-align: center;
}
</style>